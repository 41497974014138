import { render, staticRenderFns } from "./SplitListView.vue?vue&type=template&id=32d92558&scoped=true"
import script from "./SplitListView.vue?vue&type=script&lang=js"
export * from "./SplitListView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32d92558",
  null
  
)

export default component.exports