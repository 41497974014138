<template>
  <v-list-item
    v-if="profile !== null && profile !== 'undefined'"
    @click="selectProfile"
  >
    <v-list-item-avatar
      :color="profile.active ? 'green' : 'red'"
    >
      <v-icon
        v-if="profile.active"
        dark
      >
        mdi-check
      </v-icon>
      <v-icon
        v-else
        dark
      >
        mdi-close
      </v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        class="font-weight-bold text-wrap text-break"
        v-text="profile.title"
      />
      <v-list-item-subtitle
        class="text-wrap text-break"
        v-text="profile.description"
      />
    </v-list-item-content>
    <v-list-item-action-text>
      <span>
        <v-chip
          v-if="typeof profile.discountPercent !== 'undefined' && profile.discountPercent > 0"
          class="mr-2"
          style="font-weight: 300;"
        >
          {{ profile.discountPercent }}% off
        </v-chip>
        <v-chip
          v-if="typeof profile.type !== undefined && profile.type !== null && profile.type.length > 0"
          class="font-weight-bold"
          color="primary"
          outlined
          v-text="profile.type"
        />
      </span>
    </v-list-item-action-text>
  </v-list-item>
</template>

<script>
export default {
  name: "YessProfilesListItem",
  props: {
    profile: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    selectProfile() {
      this.$emit('selectProfile', this.profile)
    }
  }
}
</script>

<style scoped>

</style>