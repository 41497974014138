<template>
  <div>
    <ActionList
      :fixed-filters="fixedFilters"
      :load-items-callback="loadItemsCallback"
      :actions="actions"
      :call-to-actions="callToActions"
      :empty-text="$t('noProfilesFound')"
      :filters="filters"
    > 
      <template v-slot:item="{ item, index }">
        <YessProfilesListItem
          :profile="item"
          :key="index"
          @selectProfile="selectProfile"
        />
      </template>
    </ActionList>
  </div>
</template>

<script>
import ActionList from "../common/list/ActionList"
import YessProfilesListItem from "./YessProfilesListItem"
export default {
  name: "YessProfilesList",
  props: {
    profiles: {
      type: Array,
      default: () => null
    }, 
    loadItemsCallback: {
      type: Function, 
      default: () => null
    },
    fixedFilters: {
        type: Array,
        default: () => []
    },
  },
  components: {
    YessProfilesListItem,
    ActionList
  },
  data() {
    return {
      filters: []
    }
  },
  methods: {
    selectProfile(profile) {
      this.$emit('selectProfile', profile)
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

