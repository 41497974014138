<template>
  <v-row
    style="height: 100vh"
  >
    <v-col
      class="pt-0 pb-0 pr-0"
      cols="12"
      md="4"
      style="border-right: 1px solid #ddd;"
    >
      <v-card
        tile
        flat
        max-height="100vh"
        class="overflow-y-auto"
      >
        <slot name="list" />
      </v-card>
    </v-col>
    <v-col
      v-if="!isMobileView || typeof showBottomSheet === 'undefined' || showBottomSheet === null"
      class="grey lighten-3 pl-0 pt-0"
    >
      <v-card
        tile
        flat
        max-height="100vh"
        class="overflow-y-auto"
      >
        <slot name="view" />
      </v-card>
    </v-col>
    <v-bottom-sheet
      v-else
      v-model="showBottomSheet"
    >
      <v-card
        tile
        flat
        class="overflow-y-auto"
      >
        <slot name="view" />
      </v-card>
    </v-bottom-sheet>
  </v-row>
</template>

<script>
export default {
	name: "SplitListView",
  props: {
    showBottomSheet: {
      type: Boolean,
      default: null
    }
  },
  computed: {
    isMobileView() {
      return this.$vuetify.breakpoint.smAndDown
    }
  }
}
</script>

<style scoped>

</style>
