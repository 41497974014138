<template>
  <v-card
    max-height="100vh"
    class="overflow-y-auto"
  >
    <v-card-title>
      {{ profile.title ? profile.title : 'New Yess Profile' }}
      <v-chip
        v-if="op === 'update'"
        outlined
        small
        :color="profile.active ? 'green' : 'red'"
        class="ml-3"
        style="font-weight: 700;"
      >
        <span v-if="profile.active">
          Active
        </span>
        <span v-else>
          Inactive
        </span>
      </v-chip>
      <v-spacer />
      <v-btn
        v-if="profile.active"
        color="red"
        :loading="updatingProfileStatus"
        :dark="!updatingProfileStatus && !processing"
        :disabled="processing"
        @click="disableYessProfile"
      >
        Disable
      </v-btn>
      <v-btn
        v-else
        color="green"
        :loading="updatingProfileStatus"
        :dark="!updatingProfileStatus && !processing"
        :disabled="processing"
        @click="enableYessProfile"
      >
        Enable
      </v-btn>
    </v-card-title>

    <YessLinkForm
      :op="op"
      v-model="profile"
      :organization="organization"
      class="ma-5"
      :saving="processing"
      @processing="(e) => { this.processing = e }"
      @organizationSelected="organizationSelected"
      @valid="(e) => { this.valid = e }"
    />

    <v-card-actions>
      <v-btn
        v-if="op === 'create'"
        block
        color="primary"
        @click="createYessLink"
        :loading="processing"
        :disabled="processing || !valid"
      >
        Create
      </v-btn>
      <v-btn
        v-if="op === 'update'"
        block
        color="primary"
        @click="updateYessLink"
        :loading="processing"
        :disabled="processing || !valid"
      >
        Update
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import YessLinkForm from './YessLinkForm'

export default {
	name: 'YessLinkDialog',
	props: {
		organization: {
			type: Object,
			default: () => ({})
		},
		yessProfile: {
			type: Object,
			default: () => ({})
		},
		namespace: {
			type: String,
			default: null
		}
	},
	components: {
		YessLinkForm
	},
	data() {
		return {
			profile: {},
			processing: false,
			op: 'create',
			updatingProfileStatus: false,
			valid: false
		}
	},
	methods: {
		enableYessProfile() {
			this.updatingProfileStatus = true
			this.processing = true

			this.$store.dispatch('enableYessProfile', this.profile).then(result => {
				if(result) {
					this.$store.commit('updateActionSuccess', {
						message: this.$t('success')
					})
				} else {
					this.$store.commit('updateActionError', {
						message: this.$t('failure')
					})
				}
			}).catch(() => {
				this.$store.commit('updateActionError', {
					message: this.$t('failure')
				})
			}).finally(() => {
				this.processing = false
				this.updatingProfileStatus = false
			})
		},
		disableYessProfile() {
			this.updatingProfileStatus = true
			this.processing = true

			this.$store.dispatch('disableYessProfile', this.profile).then(result => {
				if(result) {
					this.$store.commit('updateActionSuccess', {
						message: this.$t('success')
					})
				} else {
					this.$store.commit('updateActionError', {
						message: this.$t('failure')
					})
				}
			}).catch(() => {
				this.$store.commit('updateActionError', {
					message: this.$t('failure')
				})
			}).finally(() => {
				this.processing = false
				this.updatingProfileStatus = false
			})
		},
		close() {
			if(this.processing) { return }
			this.$emit('closed')
		},
		createYessLink() {
			this.processing = true

			this.$store.dispatch('createYessLink', this.profile).then(result => {
				if(result) {
					this.$store.commit('updateActionSuccess', {
						message: this.$t('success')
					})
				} else {
					this.$store.commit('updateActionError', {
						message: this.$t('failure')
					})
				}
			}).catch(() => {
				this.$store.commit('updateActionError', {
					message: this.$t('failure')
				})
			}).finally(() => {
				this.processing = false
				this.$emit('closed')
			})
		},
		updateYessLink() {
			this.processing = true

			this.$store.dispatch('updateYessLink', this.profile).then(result => {
				if(result) {
					this.$store.commit('updateActionSuccess', {
						message: this.$t('success')
					})
				} else {
					this.$store.commit('updateActionError', {
						message: this.$t('failure')
					})
				}
			}).catch(() => {
				this.$store.commit('updateActionError', {
					message: this.$t('failure')
				})
			}).finally(() => {
				this.processing = false
				this.$emit('closed')
			})
		},
		organizationSelected(uuid) {
			this.profile.organization = uuid
		}
	},
	watch: {
		organization: function(newValue) {
			if(typeof newValue !== 'undefined' && newValue !== null) {
				if(typeof newValue.uuid !== 'undefined' && newValue.uuid !== null) {
					this.profile.organization = newValue.uuid
				} else {
					this.profile.organization = newValue
				}
			}
		},
		yessProfile: function(newValue) {
			if(newValue !== null && newValue.organization !== null && newValue.organization !== undefined) {
				this.profile.title = newValue.title
				this.profile.slug = newValue.slug
				this.profile.description = newValue.description
				this.profile.active = newValue.active
				this.profile.namespace = newValue.namespace

				if(newValue.channel !== null && newValue.channel !== undefined) {
					this.profile.channel = newValue.channel.uuid
				}

				if(newValue.location !== null && newValue.location !== undefined) {
					this.profile.location = newValue.location.uuid
				}

				if(newValue.bookTableItem !== null && newValue.bookTableItem !== undefined) {
					this.profile.bookTableItem = newValue.bookTableItem.uuid
				}

				this.profile.categories = newValue.categories
				this.profile.cateringCategories = newValue.cateringCategories
				this.profile.groups = newValue.groups
				this.profile.type = newValue.type
				this.profile.bookTableUrl = newValue.bookTableUrl
				this.profile.discountPercent = newValue.discountPercent ? newValue.discountPercent : 0
				this.profile.primaryColor = newValue.primaryColor
				this.profile.accentColor = newValue.accentColor
				this.profile.otherPaymentMethodInstances = newValue.otherPaymentMethodInstances
				this.profile.paymentMethodInstances = newValue.paymentMethodInstances
				this.profile.logo = newValue.logo
				this.profile.cover = newValue.cover
				this.profile.availableActivities = Array.isArray(newValue.availableActivities) ? newValue.availableActivities.map(item => item.uuid).filter(notNull => notNull) : []

				this.op = 'update'
			} else {
				this.profile = {}
				this.op = 'create'
			}
		},
	},
	mounted() {
		if(this.organization && this.organization.uuid) {
			this.profile.organization = this.organization.uuid
		}

		if(this.yessProfile !== null && this.yessProfile.organization !== null && this.yessProfile.organization !== undefined) {
			this.profile.title = this.yessProfile.title
			this.profile.slug = this.yessProfile.slug
			this.profile.description = this.yessProfile.description
			this.profile.active = this.yessProfile.active
			this.profile.namespace = this.yessProfile.namespace

			if(this.yessProfile.channel !== null && this.yessProfile.channel !== undefined) {
				this.profile.channel = this.yessProfile.channel.uuid
			}

			if(this.yessProfile.location !== null && this.yessProfile.location !== undefined) {
				this.profile.location = this.yessProfile.location.uuid
			}

			if(this.yessProfile.bookTableItem !== null && this.yessProfile.bookTableItem !== undefined) {
				this.profile.bookTableItem = this.yessProfile.bookTableItem.uuid
			}

			this.profile.categories = this.yessProfile.categories
			this.profile.cateringCategories = this.yessProfile.cateringCategories
			this.profile.groups = this.yessProfile.groups
			this.profile.type = this.yessProfile.type
			this.profile.bookTableUrl = this.yessProfile.bookTableUrl
			this.profile.discountPercent = this.yessProfile.discountPercent ? this.yessProfile.discountPercent : 0
			this.profile.primaryColor = this.yessProfile.primaryColor
			this.profile.accentColor = this.yessProfile.accentColor
			this.profile.otherPaymentMethodInstances = this.yessProfile.otherPaymentMethodInstances
			this.profile.logo = this.yessProfile.logo
			this.profile.cover = this.yessProfile.cover

			this.profile.availableActivities = Array.isArray(this.yessProfile.availableActivities) ? this.yessProfile.availableActivities.map(item => item.uuid).filter(notNull => notNull) : []

			this.op = 'update'
		}

		if(typeof this.profile.namespace === 'undefined' || this.profile.namespace === null) {
			if(typeof this.namespace !== 'undefined' && this.namespace !== null) {
				this.profile.namespace = this.namespace
			}
		}
	}
}
</script>
